html, body, #root {
    height: 100%;

}

a {
    color: inherit;
    text-decoration: inherit;
}

.Puzzless {
    display: flex;
    flex-direction: column;

    height: 100%;
}

@media (max-width: 600px) {
    .Puzzless-header {
        font-size: 3vmin;
    }
}

@media (min-width: 600px) {
    .Puzzless-header {
        font-size: 24px;
    }
}

h1 {
    margin: 0.2em;
}

.Puzzless-header {
    background-color: #282c34;
    justify-content: center;
    color: white;
    text-align: center;
}

div.featured {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.1s
}

div.featured:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

div.featured div.preview {
    grid-column-start: 1;
    margin-left: auto;
}

div.featured div.preview img {
    max-width: 40vw;
}

div.featured div.description {
    grid-column-start: 2;
    margin-left: 20px;
    margin-right: auto;
    font-size: 1em;
    line-height: 2;
    align-items: center;
}

div.featured div.description span {
    display: block;
}

div.featured div.description span.genre {
    font-size: 150%;
    display: block;
}

#puzzle-body {
    flex-grow: 1;

    display: flex;
    flex-direction: column;

    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0.5em;
}

#puzzle-container {
    position: relative;

    flex-grow: 1;

    display: flex;
    flex-direction: column;
}

#puzzle {
    position: absolute;

    /* Account for borders in the size of this element. */
    box-sizing: border-box;

    display: grid;
    width: calc(70vmin - 12px);
    height: calc(70vmin - 12px);
    user-select: none;
    border-style: solid;
    border-color: #282c34;
    border-width: 5px 4px 4px 5px;
}

.cell {
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #282c34;
    border-right: 1px solid #282c34;
}

.cell.wall_top:before {
    content: "";
    position: absolute;
    top: -3px;
    bottom: -3px;
    left: -3px;
    right: -3px;
    z-index: -1;
    border-top: 2px solid #282c34;
}

.cell.wall_right:before {
    content: "";
    position: absolute;
    top: -3px;
    bottom: -3px;
    left: -3px;
    right: -3px;
    z-index: -1;
    border-right: 2px solid #282c34;
}

.cell.wall_bottom:before {
    content: "";
    position: absolute;
    top: -3px;
    bottom: -3px;
    left: -3px;
    right: -3px;
    z-index: -1;
    border-bottom: 2px solid #282c34;
}

.cell.wall_left:before {
    content: "";
    position: absolute;
    top: -3px;
    bottom: -3px;
    left: -3px;
    right: -3px;
    z-index: -1;
    border-left: 2px solid #282c34;
}

#starbattle-star-count {
    font-size: 2em;
    display: flex;
    justify-content: flex-end;
}

.cell.given.digit2 {
    font-size: 80%;
    position: relative;
}

.cell.given.digit2 span:nth-child(1) {
    position: absolute;
    top: 5%;
    left: 20%;
}

.cell.given.digit2 span:nth-child(2) {
    position: absolute;
    bottom: 5%;
    right: 20%;
}

.cell.given.digit3 {
    font-size: 80%;
    position: relative;
}

.cell.given.digit3 span:nth-child(1) {
    position: absolute;
    top: 5%;
    left: 15%;
}

.cell.given.digit3 span:nth-child(2) {
    position: absolute;
    top: 5%;
    right: 15%;
}

.cell.given.digit3 span:nth-child(3) {
    position: absolute;
    bottom: 5%;
}

.cell.given.digit4 {
    font-size: 80%;
    position: relative;
}

.cell.given.digit4 span:nth-child(1) {
    position: absolute;
    top: 0%;
}

.cell.given.digit4 span:nth-child(2) {
    position: absolute;
    right: 15%;
}

.cell.given.digit4 span:nth-child(3) {
    position: absolute;
    bottom: 0%;
}

.cell.given.digit4 span:nth-child(4) {
    position: absolute;
    left: 15%;
}

.shaded {
    background: gray;
}
